<owl-carousel-o [options]="homeSlidesOptions">
  <ng-template carouselSlide *ngFor="let Content of mainBannerItem;">
    <div class="banner">
      <img [src]="Content.image" alt="Slide Image" class="slide-image" />
      <div class="search-bar" (click)="toggleLocations()">
        <h1>Properties to buy in Noida</h1>
        <div class="segment-buttons">
          <button [class.active]="activeSegment === 'buy'" (click)="setActiveSegment('buy')">Buy</button>
          <button [class.active]="activeSegment === 'rent'" (click)="setActiveSegment('rent')">Rent</button>
          <button [class.active]="activeSegment === 'sale'" (click)="setActiveSegment('sale')">Sale</button>
          <button [class.active]="activeSegment === 'plot'" (click)="setActiveSegment('plot')">Plots</button>
        </div>
        <input type="text" text-white placeholder="Search..." (input)="onInputChange($event.target.value)">
        <div class="locations" [hidden]="!showLocations">
          <ul>
            <li *ngFor="let suggestion of suggestions" (click)="selectSuggestion(suggestion)">
              <a *ngIf="activeSegment === 'buy' && suggestion === 'Noida Sector 121'"
                [routerLink]="['/sticky-product']">{{ suggestion }}</a>
              <a *ngIf="activeSegment === 'buy' && suggestion === 'Noida Sector 115'"
                [routerLink]="['/simple-product']">{{ suggestion }}</a>
              <a *ngIf="activeSegment === 'buy' && suggestion === 'Noida Sector 107'"
                [routerLink]="['/slider-product']">{{ suggestion }}</a>
              <a *ngIf="activeSegment === 'rent' && suggestion === 'Noida Sector 121'"
                [routerLink]="['/shop-left-sidebar-1']">{{ suggestion }}</a>
              <a *ngIf="activeSegment === 'rent' && suggestion === 'Noida Sector 115'"
                [routerLink]="['/shop-left-sidebar-2']">{{ suggestion }}</a>
              <a *ngIf="activeSegment === 'rent' && suggestion === 'Noida Sector 107'"
                [routerLink]="['/shop-right-sidebar-1']">{{ suggestion }}</a>
              <a *ngIf="activeSegment === 'sale' && suggestion === 'Noida Sector 121'"
                [routerLink]="['/shop-full-width1']">{{ suggestion }}</a>
              <a *ngIf="activeSegment === 'sale' && suggestion === 'Noida Sector 115'"
                [routerLink]="['/shop-left-sidebar-2']">{{ suggestion }}</a>
              <a *ngIf="activeSegment === 'sale' && suggestion === 'Noida Sector 107'"
                [routerLink]="['/shop-right-sidebar-1']">{{ suggestion }}</a>
              <a *ngIf="activeSegment === 'plot' && suggestion === 'Noida Sector 121'"
                [routerLink]="['/sticky-product']">{{ suggestion }}</a>
              <a *ngIf="activeSegment === 'plot' && suggestion === 'Noida Sector 115'"
                [routerLink]="['/simple-product']">{{ suggestion }}</a>
              <a *ngIf="activeSegment === 'plot' && suggestion === 'Noida Sector 107'"
                [routerLink]="['/blog-right-sidebar']">{{ suggestion }}</a>
              <span
                *ngIf="activeSegment !== 'buy' && activeSegment !== 'rent' && activeSegment !== 'sale' && activeSegment !== 'plot'">{{
                suggestion }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-template>
</owl-carousel-o>

<!-- End Main Banner Area -->
<section class="section rent">
  <h3>Housing Edge</h3>
  <div class="slider">
    <div class="card">
      <img src="/assets/img/debit-card.png" class="icon">
      <p>Pay on Credit</p>
    </div>
    <div class="card">
      <img src="/assets/img/personal-loan.png" class="icon">
      <p>Personal Loans</p>
    </div>
    <div class="card">
      <img src="/assets/img/quick-cash.png" class="icon">
      <p>Quick Cash</p>
    </div>
    <div class="card">
      <img src="/assets/img/rent.png" class="icon">
      <p>Rent Now, Pay Later</p>
    </div>
    <div class="card">
      <img src="/assets/img/agreement.jpg" class="icon">
      <p>Rent Agreement</p>
    </div>
    <div class="card">
      <img src="/assets/img/home-loan.png" class="icon">
      <p>Home Loan</p>
    </div>
    <div class="card">
      <img src="/assets/img/property-management.png" class="icon">
      <p>Property Mgmt</p>
    </div>
  </div>

  <div class="title" style="margin-top: 20px;">
    <h1>Recently Added Properties</h1>
    <p>
      Search over 2000 properties to recent properties from the top agents in the country
    </p>
  </div>

  <div class="rent-center container">
    <div class="box">
      <div class="top">
        <div class="overlay">
          <img src="assets/img/property1.jpg" alt="" />
        </div>
        <div class="pos">

          <span>BUY</span>
        </div>
      </div>
      <div class="bottom">
        <p>Cleo County</p>
        <h5 class="text">4 BHK Apartment<br>
          Cleo County, Sector 121, Noida</h5>
        <div>
          <span>₹13.58 K/sq.ft</span>
          <span><i class="far fa-eye"></i></span>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="top">
        <div class="overlay">
          <img src="assets/img/property2.jpg" alt="" />
        </div>
        <div class="pos">

          <span>BUY</span>
        </div>
      </div>
      <div class="bottom">
        <p>Homes 121</p>
        <h5 class="text">Sector 121, Noida</h5>

        <div>
          <span>Price per Sqft
            ₹5714 - 6214</span>
          <span><i class="far fa-eye"></i></span>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="top">
        <div class="overlay">
          <img src="assets/img/property3.jpg" alt="" />
        </div>
        <div class="pos">

          <span>BUY</span>
        </div>
      </div>
      <div class="bottom">
        <p>Residential Apartments</p>
        <h5 class="text">Sector 121, Noida</h5>
        <div>
          <span>
            ₹8,000/ Square Feet</span>
          <span><i class="far fa-eye"></i></span>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="top">
        <div class="overlay">
          <img src="assets/img/property9.jpg" alt="" />
        </div>
        <div class="pos">

          <span>BUY</span>
        </div>
      </div>
      <div class="bottom">
        <p> Luxury Locality</p>
        <h5 class="text">Sector 107, Noida</h5>
        <div>
          <span>₹10,550 /sq.ft</span>
          <span><i class="far fa-eye"></i></span>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="top">
        <div class="overlay">
          <img src="assets/img/property5.jpg" alt="" />
        </div>
        <div class="pos">

          <span>BUY</span>
        </div>
      </div>
      <div class="bottom">
        <p>Residential Plot</p>
        <h5 class="text">Sector 115, Noida</h5>
        <div>
          <span>₹550 Sqft Plot</span>
          <span><i class="far fa-eye"></i></span>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="top">
        <div class="overlay">
          <img src="assets/img/property6.jpg" alt="" />
        </div>
        <div class="pos">

          <span>BUY</span>
        </div>
      </div>
      <div class="bottom">
        <p>Land/Plots for Sale</p>
        <h5 class="text">Sector 115, Noida</h5>
        <div>
          <span>₹200 Sq.Yd. (Plot Area)</span>
          <span><i class="far fa-eye"></i></span>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="top">
        <div class="overlay">
          <img src="assets/img/property7.jpg" alt="" />
        </div>
        <div class="pos">

          <span>BUY</span>
        </div>
      </div>
      <div class="bottom">
        <p>Sunworld Vanalika</p>
        <h5 class="text">3 BHK
          Apartment Sector 107, Noida</h5>
        <div>
          <span>₹1405 - 1730 sq.ft.
          </span>
          <span><i class="far fa-eye"></i></span>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="top">
        <div class="overlay">
          <img src="assets/img/property8.jpg" alt="" />
        </div>
        <div class="pos">

          <span>BUY</span>
        </div>
      </div>
      <div class="bottom">
        <p>Prateek Edifice</p>
        <h5 class="text">Sector 107, Dadri Road, Noida</h5>
        <div>
          <span>₹2070 - 4300 Sq.Ft</span>
          <span><i class="far fa-eye"></i></span>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- Featured property -->
<section class="section rent" style="margin-top: -160px;">
  <div class="title">
    <h1>Discover Our Featured Listings</h1>
    <p>
      These featured listings contain exclusive real estate opportunities
      within the city(Noida)
    </p>
  </div>

  <owl-carousel-o [options]="productsSlidesOptions">
    <ng-template carouselSlide *ngFor="let Content of mainBannerItem1;">
      <div class="rent-center container">
        <div class="box">
          <div class="top">
            <div class="overlay">
              <img [src]="Content.image" alt="" />
            </div>
            <div class="pos">
              <span>FEATURED</span>
              <span>BUY</span>
            </div>
          </div>
          <div class="bottom">
            <p>{{Content.title}}</p>
            <h5 class="text">{{Content.paragraph}}</h5>
            <div>
              <span>{{Content.price}}</span>
              <span><i class="far fa-eye"></i></span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
</section>

<!-- Grid -->
<section class="section rent" style=" margin-top: -164px;">

  <div class="title">
    <h1>Trending Projects</h1>
    <p>
      Most sought-after projects in Noida
    </p>
  </div>

  <div class="rent-center container">
    <div class="box">
      <div class="top">
        <div class="overlay">
          <img src="assets/img/property1.jpg" alt="" />
        </div>
        <div class="pos">

          <span>BUY</span>
        </div>
      </div>
      <div class="bottom">
        <p>3 BHK Apartment in County County</p>
        <h5 class="text"><br>
          Sector 121, Noida</h5>
        <div>
          <span>₹ 1.28 Cr</span>
          <span><i class="far fa-eye"></i></span>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="top">
        <div class="overlay">
          <img src="assets/img/Paramount Golfforeste Premium Apartments.jpg" alt="" />
        </div>
        <div class="pos">

          <span>BUY</span>
        </div>
      </div>
      <div class="bottom">
        <p>Paramount Golfforeste Premium Apartments</p>
        <h5 class="text">Sector 121, Noida</h5>

        <div>
          <span>₹51.66 L - 70.93 L</span>
          <span><i class="far fa-eye"></i></span>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="top">
        <div class="overlay">
          <img src="assets/img/Gulshan Avante.jpg" alt="" />
        </div>
        <div class="pos">

          <span>BUY</span>
        </div>
      </div>
      <div class="bottom">
        <p>Gulshan Avante</p>
        <h5 class="text">Sector 121, Noida</h5>
        <div>
          <span>
            ₹5.1 Cr</span>
          <span><i class="far fa-eye"></i></span>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="top">
        <div class="overlay">
          <img src="assets/img/Purvanchal Royal City Phase 2.jpg" alt="" />
        </div>
        <div class="pos">

          <span>BUY</span>
        </div>
      </div>
      <div class="bottom">
        <p>Purvanchal Royal City</p>
        <h5 class="text">Sector 107, Noida</h5>
        <div>
          <span>₹1.23 Cr</span>
          <span><i class="far fa-eye"></i></span>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="top">
        <div class="overlay">
          <img src="assets/img/33.jpg" alt="" />
        </div>
        <div class="pos">
          <span>BUY</span>
        </div>
      </div>
      <div class="bottom">
        <p>3 BHK Apartment in County Group</p>
        <h5 class="text">Sector 115, Noida</h5>
        <div>
          <span>₹ 2.25 Cr</span>
          <span><i class="far fa-eye"></i></span>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="top">
        <div class="overlay">
          <img src="assets/img/ATS Homekraft Pious Orchards.jpg" alt="" />
        </div>
        <div class="pos">
          <span>BUY</span>
        </div>
      </div>
      <div class="bottom">
        <p>ATS Homekraft Pious Orchards</p>
        <h5 class="text">Sector 115, Noida</h5>
        <div>
          <span>₹1.95 Cr - 2.62 Cr</span>
          <span><i class="far fa-eye"></i></span>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="top">
        <div class="overlay">
          <img src="assets/img/property7.jpg" alt="" />
        </div>
        <div class="pos">
          <span>BUY</span>
        </div>
      </div>
      <div class="bottom">
        <p>Sunworld Vanalika</p>
        <h5 class="text">Sector 107, Noida</h5>
        <div>
          <span>₹1405 - 1730 sq.ft.
          </span>
          <span><i class="far fa-eye"></i></span>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="top">
        <div class="overlay">
          <img src="assets/img/property8.jpg" alt="" />
        </div>
        <div class="pos">
          <span>BUY</span>
        </div>
      </div>
      <div class="bottom">
        <p>Prateek Edifice</p>
        <h5 class="text">Sector 107, Dadri Road, Noida</h5>
        <div>
          <span>₹2070 - 4300 Sq.Ft</span>
          <span><i class="far fa-eye"></i></span>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- slider -->
<section class="section rent" style="margin-top: -160px;">
  <div class="title">
    <h1>PremVastu Contact</h1>
    <p>
      These featured listings contain exclusive real estate opportunities
      within the city(Noida)
    </p>
  </div>
  <owl-carousel-o [options]="productsSlidesOptions">
    <ng-template carouselSlide *ngFor="let Content of mainBannerItem2;">
      <div class="rent-center container">
        <div class="box">
          <div class="top">
            <div class="overlay">
              <img [src]="Content.image" alt="" />
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
</section>

<!-- Grid -->
<section class="section grid-layout" style="margin-top: -79px;">
  <div class="title">
    <h1>Popular Properties</h1>
  </div>

  <div class="wrapper container">
    <div class="box box1">
      <img src="assets/img/images/County 107.jpg" alt="" />
    </div>
    <div class="box box2">
      <img src="assets/img/images/County 115.jpg" alt="" />
    </div>
    <div class="box box3">
      <img src="assets/img/images/Cleo county.jpg" alt="" />
    </div>
    <div class="box box4">
      <img src="assets/img/images/Godrej.jpg" alt="" />
    </div>
    <div class="box box5">
      <img src="assets/img/images/L&T.jpg" alt="" />
    </div>
    <div class="box box6">
      <img src="assets/img/images/Cleo gold.jpg" alt="" />
    </div>
    <div class="box box7">
      <img src="assets/img/images/ATS.jpg" alt="" />
    </div>
    <div class="box box8">
      <img src="assets/img/images/Coco county.jpg" alt="" />
    </div>
  </div>
</section>

<!-- Contact -->
<section class="section contact" style="margin-top: -106px;">
  <div class="row">
    <div class="col">
      <h2>EXCELLENT SUPPORT</h2>
      <p>
        We love our customers and they can reach us any time of day we will
        be at your service 24/7
      </p>
      <a href="/contact" class="btn-1">Contact</a>
    </div>
    <div class="col">
      <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
        <div>
          <input formControlName="email" type="text" class="form-control" placeholder="Your email address">
          <div
            *ngIf="contactForm.get('email').invalid && (contactForm.get('email').dirty || contactForm.get('email').touched)">
            <div *ngIf="contactForm.get('email').errors.required" style="color: black;">Email Address is required.</div>
            <div *ngIf="contactForm.get('email').errors.email" style="color: black;">Invalid email format.</div>
          </div>
          <div class="alert alert-success" *ngIf="isSuccess">
            <p style="color: black;">Email submitted successfully!</p>
          </div>
          <div class="alert alert-danger" *ngIf="isFormSubmitted && !contactForm.valid">
            <p style="color: black;">Please fill in the required fields.</p>
          </div>
          <button><a>Send</a></button>
        </div>
      </form>
    </div>
  </div>
</section>

<!-- Footer -->
<!-- <footer class="footer">
    <div class="row">
      <div class="col d-flex">
        <h4>INFORMATION</h4>
        <a href="">About us</a>
        <a href="">Contact Us</a>
        <a href="">Term & Conditions</a>
        <a href="">Shipping Guide</a>
      </div>
      <div class="col d-flex">
        <h4>USEFUL LINK</h4>
        <a href="">Online Store</a>
        <a href="">Customer Services</a>
        <a href="">Promotion</a>
        <a href="">Top Brands</a>
      </div>
      <div class="col d-flex">
        <span><i class="bx bxl-facebook-square"></i></span>
        <span><i class="bx bxl-instagram-alt"></i></span>
        <span><i class="bx bxl-github"></i></span>
        <span><i class="bx bxl-twitter"></i></span>
        <span><i class="bx bxl-pinterest"></i></span>
      </div>
    </div>
  </footer>  -->