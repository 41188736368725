<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area mt-0" *ngFor="let Content of pageTitle;"
    style="background-image: url({{Content.bgImage}});">
    <h1>{{Content.title}}</h1>
    <!-- <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{Content.title}}</li>
    </ul> -->
</div>
<!-- End Page Title Area -->

<!-- Start Customer Service Area -->
<section class="customer-service-area ptb-100">
    <div class="container">
        <div class="customer-service-content">
            <h3 style="margin-top:10px">PetVays Privacy Policy</h3>
            <p style="text-align: justify;">
                Your privacy is important to us
                Petvays is located at:
                Petvays
                H-161, Noida, Uttar Pradesh, 201301, India Noida, Noida
                201301 Uttar Pradesh , India
                (+91)8630649605
                 <br><br>
                It is Petvays's policy to respect your privacy regarding any information we may collect while operating
                our website. This Privacy Policy applies to petvays.com/ (hereinafter, "us", "we", or "petvays.com/").
                We respect your privacy and are committed to protecting personally identifiable information you may
                provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what
                information may be collected on our Website, how we use this information, and under what circumstances
                we may disclose the information to third parties. This Privacy Policy applies only to information we
                collect through the Website and does not apply to our collection of information from other sources.
                This Privacy Policy, together with the Terms of Service posted on our Website, set forth the general
                rules and policies governing your use of our Website. Depending on your activities when visiting our
                Website, you may be required to agree to additional terms of service.
                <!-- <strong>1.</strong> Complimentary ground shipping within 1 to 7 business days<br> -->
                <!-- <strong>2.</strong> In-store collection available within 1 to 7 business days<br>
                <strong>3.</strong> Next-day and Express delivery options also available<br>
                <strong>4.</strong> Purchases are delivered in an orange box tied with a Bolduc ribbon, with the exception of certain items<br>
                <strong>5.</strong> See the delivery FAQs for details on shipping methods, costs and delivery times -->
            </p>
           <h3>1. Website Visitors</h3>
            <p>Like most website operators, Petvays collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Petvays's purpose in collecting non-personally identifying information is to better understand how Petvays's visitors use its website. From time to time, Petvays may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.
             Petvays also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on https://petvays.com/ blog posts. Petvays only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.</p>
            <h3>2. Personally-Identifying Information</h3>
            <p>Certain visitors to Petvays's websites choose to interact with Petvays in ways that require Petvays to gather personally-identifying information. The amount and type of information that Petvays gathers depends on the nature of the interaction. For example, we ask visitors who leave a comment at https://petvays.com/ to provide a username and email address.</p>
            <h3>3. Security</h3>
            <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
            <h3>4. Links To External Sites</h3>
            <p>Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms of service of every site you visit.
             We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.</p>
            <h3>5. Petvays uses Google AdWords for remarketing</h3>
            <p>Petvays uses the remarketing services to advertise on third party websites (including Google) to previous visitors to our site. It could mean that we advertise to previous visitors who haven't completed a task on our site, for example using the contact form to make an enquiry. This could be in the form of an advertisement on the Google search results page, or a site in the Google Display Network. Third-party vendors, including Google, use cookies to serve ads based on someone's past visits. Of course, any data collected will be used in accordance with our own privacy policy and Google's privacy policy.
             You can set preferences for how Google advertises to you using the Google Ad Preferences page, and if you want to you can opt out of interest-based advertising entirely by cookie settings or permanently using a browser plugin.</p> 
            <h3>6. Protection of Certain Personally-Identifying Information</h3>
            <p>Petvays discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on Petvays's behalf or to provide services available at Petvays's website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Petvays's website, you consent to the transfer of such information to them. Petvays will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Petvays discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when Petvays believes in good faith that disclosure is reasonably necessary to protect the property or rights of Petvays, third parties or the public at large.
             If you are a registered user of https://petvays.com/ and have supplied your email address, Petvays may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what's going on with Petvays and our products. We primarily use our blog to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Petvays takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.</p> 
            <h3>7. Affiliate Disclosure</h3>
            <p>This site uses affiliate links and does earn a commission from certain links. This does not affect your purchases or the price you may pay. </p> 
            <h3>8. Cookies</h3>
            <p>To enrich and perfect your online experience, Petvays uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.
            A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. Petvays uses cookies to help Petvays identify and track visitors, their usage of https://petvays.com/, and their website access preferences. Petvays visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Petvays's websites, with the drawback that certain features of Petvays's websites may not function properly without the aid of cookies.
            By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to Petvays's use of cookies.</p> 
            <h3>9. E-commerce</h3>
            <p>Those who engage in transactions with Petvays – by purchasing Petvays's services or products, are asked to provide additional information, including as necessary the personal and financial information required to process those transactions. In each case, Petvays collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor's interaction with Petvays. Petvays does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities.</p>  
            <h3>10. Business Transfers</h3>
            <p>If Petvays, or substantially all of its assets, were acquired, or in the unlikely event that Petvays goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of Petvays may continue to use your personal information as set forth in this policy.</p> 
            <h3>11. Privacy Policy Changes</h3>
            <p>Although most changes are likely to be minor, Petvays may change its Privacy Policy from time to time, and in Petvays's sole discretion. Petvays encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p> 
        </div>
        <button routerLink="/terms-of-service" class="button button3"><b>Read Term Of Service</b></button>
    </div>
</section>
<!-- End Customer Service Area -->