<!-- Start Navbar Area -->
<div class="navbar-area p-absolute">
    <div class="fiwan-responsive-nav">
        <div class="container">
            <div class="fiwan-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/images/logo.png" alt="logo" height="60px" width="60px"></a>
                </div>
            </div>
        </div>
    </div>
    <div class="fiwan-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/images/logo.png" alt="logo" height="120px" width="120px"></a>
                
                <div class="collapse navbar-collapse mean-menu">
                    
                 <input type="text" name="search-box" class="search-box" placeholder="Search for..." (keyup)="onSearch($event)">
                   
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item"><a routerLink="/shop-full-width-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products</a></li>
                      
                    </ul>
                    <div class="others-option d-flex align-items-center">
                       
                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="bx bx-menu-alt-left" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

</div>
