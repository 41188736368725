<!-- Start Top Header Area -->
<section class="top-header-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <ul class="top-header-contact-info">
                    <li><a href="+918630649605"><i class='bx bx-phone-call'></i>+918630649605</a></li>
                    <li><a href="mailto:ritik@premvastu.com"><i class='bx bx-envelope'></i> ritik@premvastu.com</a></li>
                </ul>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="top-header-right">
                    <ul class="d-flex align-items-center justify-content-end">
                        <!-- <li><a routerLink="/profile-authentication"><i class='bx bx-log-in'></i> Login </a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Top Header Area -->

<!-- Start Navbar Area -->

<div class="navbar-area css-11615lq">
    <div class="fiwan-responsive-nav">
        <div class="container">
            <div class="fiwan-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="/assets/img/logo3.png" alt="logo" class="logo3" style="margin-top:-5px"></a>
                    <!-- assets/img/logo1.png -->
                </div>
            </div>
        </div>
    </div>

    <div class="fiwan-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" ><img routerLink="/" src="/assets/img/logo3.png" alt="logo" class="logo3" style="margin-top:-5px"></a>
                <!-- assets/img/logo1.png -->
                <div class="collapse navbar-collapse mean-menu">
                  
                    <!-- <div class="search">
                        <input type="text"  routerLink="/search-result" placeholder="Search..">
                    </div> -->
                  
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link">Home</a>
                        </li>
                        
                        <!-- <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Shop</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop Full Width <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/shop-full-width-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Full Width Style 01</a></li>

                                        <li class="nav-item"><a routerLink="/shop-full-width-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Full Width Style 02</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop Left Sidebar <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/shop-left-sidebar-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Left Sidebar Style 01</a></li>

                                        <li class="nav-item"><a routerLink="/shop-left-sidebar-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Left Sidebar Style 02</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop Right Sidebar <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/shop-right-sidebar-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Right Sidebar Style 01</a></li>

                                        <li class="nav-item"><a routerLink="/shop-right-sidebar-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Right Sidebar Style 02</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>

                                <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>

                                <li class="nav-item"><a routerLink="/wishlist" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Wishlist</a></li>

                                <li class="nav-item"><a routerLink="/rooms" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Rooms</a></li>

                                <li class="nav-item"><a routerLink="/customer-service" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Customer Service</a></li>

                                <li class="nav-item"><a routerLink="/profile-authentication" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Account</a></li>

                                <li class="nav-item"><a routerLink="/order-tracking" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Order Tracking</a></li>
                            </ul>
                        </li> -->

                        <li class="nav-item"><a routerLink="/about" class="nav-link">About</a>
                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/collection-dog-food" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Dogs</a></li>

                                <li class="nav-item"><a href="javascript:void(0)">Cats (Coming Soon)</a></li>
                                <li class="nav-item"><a href="javascript:void(0)">Rabbits (Coming Soon)</a></li>
                                <li class="nav-item"><a href="javascript:void(0)">Fish (Coming Soon)</a></li>
                            </ul> -->
                        </li>
                        <!-- <li class="nav-item"><a routerLink="/accessories" class="nav-link">Agents</a> -->
                        <li class="nav-item"><a routerLink="/blog-grid" class="nav-link">Blog</a></li>

                        
                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog-grid" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>

                                <li class="nav-item"><a routerLink="/blog-right-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>
                                        
                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>  -->
                        
                        <li class="nav-item"><a routerLink="/contact" class="nav-link">Contact</a></li>
       
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <div class="search-box">
                                <!-- <i routerLink="/search-result" class='bx bx-search'></i> -->
                            </div>
                        </div>

                        <!-- <div class="option-item">
                            <a routerLink="/cart" class="cart-btn"><i class='bx bx-shopping-bag'></i></a>
                        </div> -->
<!-- 
                        <div class="option-item">
                            <div class="side-menu-btn">
                                <i class="bx bx-menu-alt-left" data-bs-toggle="modal" data-bs-target="#sidebarModal"></i>
                            </div>
                        </div> -->
                    </div>
                </div>
            </nav>
        </div>
    </div>

</div>


<!-- End Navbar Area -->

<!-- Search Overlay -->

<!-- End Search Overlay -->

