import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user.service';
import {AbstractControl, ValidationErrors } from '@angular/forms';
import { Firestore, collection, addDoc } from '@angular/fire/firestore';
import { Router } from '@angular/router';
@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    contactForm!: FormGroup
    isFormSubmitted = false;
    isSuccess = false;
    constructor(private formBuilder: FormBuilder, private userService: UserService, private firestore:Firestore, private router:Router) {

    }

    ngOnInit(): void {
        this.initForm()
    }

    pageTitle = [
        {
            bgImage: 'assets/img/contact3.jpg',
            title: 'Contact Us'
        }
    ]

    initForm() {
        this.contactForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
            email: ['', [Validators.required, Validators.email]],
            mobile: ['', [Validators.required, this.mobileValidator]],
            msg: [''],
            address: ['', [Validators.required]]
        });
    }
    onSubmit() {
        this.isFormSubmitted = true;
        if(this.contactForm.valid){
          const collectionInstance =  collection(this.firestore, 'users');
          addDoc(collectionInstance, this.contactForm.value).then(()=>{
            console.log("Form Submitted Successfully")
            this.isSuccess = true;
            this.isFormSubmitted = false;
            this.contactForm.reset()
          })
          .catch((error)=>{
            console.log(error)
          })
        }

    }
    // Custom validator function for mobile field
   mobileValidator(control: AbstractControl): ValidationErrors | null {
    const phoneNumberPattern = /^\d{10}$/;
  
    if (!phoneNumberPattern.test(control.value)) {
      return { 'invalidMobile': true };
    }
  
    return null;
   }
}