import { Component, OnInit } from '@angular/core';
import { SearchService } from 'src/app/search.service';

@Component({
  selector: 'app-home-demo-two',
  templateUrl: './home-demo-two.component.html',
  styleUrls: ['./home-demo-two.component.scss']
})
export class HomeDemoTwoComponent implements OnInit {
  constructor(private searchService:SearchService) {

    

   }

  ngOnInit(): void {

  }

}
