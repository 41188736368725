<app-navbar-style-two></app-navbar-style-two>
<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <!-- <h1 style="color: white">{{Content.title}}</h1> -->
    <!-- <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{Content.title}}</li>
    </ul> --> 
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area pt-100">
    <div class="container">
        <div class="contact-inner-area">
            <div><h2 style="font-size: 20px; position: relative;top: -21px;">Drop Us Email</h2></div>
            <div class="row">
                <div class="col-lg-7 col-md-12">
                    <div class="contact-form">
                        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                              <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                  <input formControlName="name" type="text" class="form-control" placeholder="Your name">
                                  <div *ngIf="contactForm.get('name').invalid && (contactForm.get('name').dirty || contactForm.get('name').touched)">
                                    <div *ngIf="contactForm.get('name').errors.required">Name is required.</div>
                                    <div *ngIf="contactForm.get('name').errors.minlength">Name must be at least 3 characters long.</div>
                                    <div *ngIf="contactForm.get('name').errors.maxlength">Name can't be longer than 50 characters.</div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                  <input formControlName="email" type="text" class="form-control" placeholder="Your email">
                                  <div *ngIf="contactForm.get('email').invalid && (contactForm.get('email').dirty || contactForm.get('email').touched)">
                                    <div *ngIf="contactForm.get('email').errors.required">Email Address is required.</div>
                                    <div *ngIf="contactForm.get('email').errors.email">Invalid email format.</div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                  <input formControlName="address" type="text" class="form-control" placeholder="Address">
                                  <div *ngIf="contactForm.get('address').invalid && (contactForm.get('address').dirty || contactForm.get('address').touched)">
                                    <div *ngIf="contactForm.get('address').errors.required">Address is required.</div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                  <input formControlName="mobile" type="number" class="form-control" placeholder="Your phone number">
                                  <div *ngIf="contactForm.get('mobile').invalid && (contactForm.get('mobile').dirty || contactForm.get('mobile').touched)">
                                    <div *ngIf="contactForm.get('mobile').errors.required">Phone number is required.</div>
                                    <div *ngIf="contactForm.get('mobile').errors.invalidMobile">Invalid phone number. Please enter a 10-digit number.</div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                  <textarea formControlName="msg" cols="30" rows="6" class="form-control" placeholder="Write your message..."></textarea>
                                  <div *ngIf="contactForm.get('msg').invalid && (contactForm.get('msg').dirty || contactForm.get('msg').touched)">
                                    <div *ngIf="contactForm.get('msg').errors.required">Message is required.</div>
                                  </div>
                                </div>
                              </div>
                              <div class="alert alert-success" *ngIf="isSuccess">
                                Form submitted successfully!
                              </div>
                              <div class="alert alert-danger" *ngIf="isFormSubmitted && !contactForm.valid">
                                Please fill in all the required fields.
                              </div>
                              <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn" [disabled]="contactForm.invalid">
                                  <i class="bx bx-paper-plane"></i> Submit
                                </button>
                              </div>
                            </div>
                          </form>
                          
                    </div>
                    
                </div>
                <div class="col-lg-5 col-md-12">
                    <div class="contact-details">
                      <p><i class="bx bx-home"></i> <b>Address:</b> Shop No 69, Ist floor, Opp Gate 1. Cleo County, Near Value Bazaar Sector 121, Noida </p>
                      <p><i class="bx bx-phone"></i> <b>Phone:</b> +918630649605</p>
                      <p><i class="bx bx-envelope"></i> <b>Email: </b>ritik@premvastu.com</p>
                      <!-- Add any other contact details you want to display -->
                    </div>
                  </div>
            </div>
            
        </div>
        
    </div>
    
</section>
<!-- End Contact Area -->

<!-- Start Map Area -->
<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56031.500355544515!2d77.29302665953634!3d28.6306980104046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cef9d2b2754f3%3A0x4a76ba1f8197ee60!2sCleo%20County!5e0!3m2!1sen!2sin!4v1690407297790!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>
<!-- End Map Area -->