import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {


  constructor(private http: HttpClient) { }

  getProduct(data:any){
    return this.http.post("https://us-central1-spotyourdeal-38ab2.cloudfunctions.net/api/getProducts/",data)
  }
}
