import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-blog-details',
    templateUrl: './blog-details.component.html',
    styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
    jsonLD: any;

    constructor(private sanitizer: DomSanitizer, private meta:Meta, public title:Title) { 
        this.title.setTitle("Learn Pet Care Advice and Tips with Petvays");
        this.meta.addTags([
            { name: 'description', content: 'PetVays will Provide You Helpful Pet Care Advice and Tips' },
            { name: "robots", content: "index,follow"},
            { property: "og:type", content: "website"},
            { property: "og:url", content: "https://petvays.com/blogs"},
            { property: "og:title", content: "Learn Pet Care Advice and Tips with Petvays"},
            { property: "og:description", content: "PetVays will Provide You Helpful Pet Care Advice and Tips"},
            { property: "og:image", content: "http://petvays.com/assets/img/dog.jpg"},
            {property: "twitter:url", content: "https://petvays.com/blogs"},
            {property: "twitter:title", content: "Learn Pet Care Advice and Tips with Petvays"},
            {property: "twitter:description", content: "PetVays will Provide You Helpful Pet Care Advice and Tips"},
            {property: "twitter:image", content: "http://petvays.com/assets/img/dog.jpg"},
            {rel: 'canonical', href: 'https://petvays.com/blogs'},
        ])
    }

    ngOnInit(): void {
        const json = {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://petvays.com/blog/xyz"
            },
            "headline": "Is Your Dog's Diet Making Them Sick? 5 Ways to Pick the Right Food",
            "description": "By following these tips on how to pick the right food for your dog’s diet, you can ensure they won’t experience any of these problems while still getting the nutrition they need to grow strong and healthy with age.",
            "image": "https://petvays.com/assets/img/dog.jpg",
            "author": {
              "@type": "Organization",
              "name": "PetVays",
              "url": "https://petvays.com/"
            },
            "publisher": {
              "@type": "Organization",
              "name": "PetVays",
              "logo": {
                "@type": "ImageObject",
                "url": "https://petvays.com/assets/img/logo.png"
              }
            },
            "datePublished": "2022-09-24",
            "dateModified": "2022-09-24"
          }
          this.jsonLD = this.getSafeHTML(json);
        }
        getSafeHTML(value: {}) {
          const json = JSON.stringify(value, null, 2);
          const html = `<script type="application/ld+json">${json}</script>`;
          // Inject to inner html without Angular stripping out content
          return this.sanitizer.bypassSecurityTrustHtml(html);
      }
    

    pageTitle = [
        {
          bgImage: 'assets/img/blog.jpg',
            // title: 'Spotlight on the Natuzzi Editions Furniture Collection'
        }
    ]

}