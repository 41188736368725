import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-leash',
  templateUrl: './leash.component.html',
  styleUrls: ['./leash.component.scss']
})
export class LeashComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  pageTitle = [
    {
        bgImage: 'assets/img/accessories.jpg',
        title: 'Similar Products'
    }
]
  singleProductsBox = [
    {
        mainImg: 'assets/img/leash.png',
        // title: 'Office Desk Sofa',
        // newPrice: '$199.00',
        // oldPrice: '$210.00',
        // sale: 'Sale!',
        // outOfStock: '',
        // detailsLink: 'simple-product'
    },
    {
        mainImg: 'assets/img/leash1.png',
        // title: 'Swivel Sofa',
        // newPrice: '$223.99',
        // oldPrice: '',
        // outOfStock: '',
        // detailsLink: 'simple-product'
    },
    {
        mainImg: 'assets/img/leash2.png',
        // title: 'Home Alisa Sofa',
        // newPrice: '$199.00',
        // oldPrice: '$210.00',
        // outOfStock: '',
        // detailsLink: 'simple-product'
    },
    {
      mainImg: 'assets/img/leash3.png',
      // title: 'Home Alisa Sofa',
      // newPrice: '$199.00',
      // oldPrice: '$210.00',
      // outOfStock: '',
      // detailsLink: 'simple-product'
  },
  {
    mainImg: 'assets/img/leash4.png',
    // title: 'Home Alisa Sofa',
    // newPrice: '$199.00',
    // oldPrice: '$210.00',
    // outOfStock: '',
    // detailsLink: 'simple-product'
},
{
  mainImg: 'assets/img/leash5.png',
  // title: 'Home Alisa Sofa',
  // newPrice: '$199.00',
  // oldPrice: '$210.00',
  // outOfStock: '',
  // detailsLink: 'simple-product'
}
]

productsSlidesOptions: OwlOptions = {
loop: true,
nav: false,
dots: true,
autoplayHoverPause: true,
autoplay: true,
margin: 30,
navText: [
  "<i class='bx bx-left-arrow-alt'></i>",
  "<i class='bx bx-right-arrow-alt'></i>"
],
responsive: {
  0: {
    items: 1
  },
  576: {
    items: 2
  },
  768: {
    items: 2
  },
  992: {
    items: 3
  },
  1200: {
    items: 3
  }
}
}
}
