import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIS } from '../config';
import { PetvaysServiceService } from '../petvays-service.service';
import { SearchService } from '../search.service';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit {
  productList: any;
  searchkey:string="";
  Content: any;
  product_list: any;
  constructor(private router:Router, private api:SearchService, private activatedRoute: ActivatedRoute, private http: PetvaysServiceService) { }

  ngOnInit(): void {
  }
  pageTitle = [
    {
        bgImage: 'assets/img/images/cc.jpg',
        title: 'Your Pets Deserve The Best'
    }
]
  searchProduct(event:any){
    console.log(event,'event..')

    let search = {search: event, "city": "Delhi NCR", "limit": 20, "offset": 0}

    this.api.getProduct(search).subscribe((res:any) => {
      this.productList = res;
    })
  }

  
  productlist() {
    let formdata = {
        "limit": 20,
        "storeid": "Pet6197",
        "offset": 0,
    }
    console.log("Response from formdata of productlist", formdata)
    this.http.post(APIS.productList, formdata, true)
        .subscribe((res: any) => {
            this.product_list = res;
            console.log("Response from product list API", this.product_list)
        }, (error: any) => {
            console.log(error)
        });


    // let formdata = {"cat":"Pet Food","page":1,"limit":14,"productId":"","cityName":"Delhi NCR","bannerType":"M7","userId":null,"storeId":["Pet6197"],"zip":"","articleNumber":[]}
    // console.log("Response from formdata of productlist", formdata)
    // this.http.post(APIS.todayOffer, formdata, true)
    //     .subscribe((res: any) => {
    //         this.product_list = res['msg'];
    //         console.log("Response from product list API", this.product_list)
    //     }, (error: any) => {
    //         console.log(error)
    //     });
}
showDetails(item: any) {
   // this.router.navigateByUrl('/simple-product', { state: { productId : item } });
   this.router.navigate(['/simple-product', item])

}

// For Pagination
shopFullWidth: number = 1;

// Category Select
singleSelect: any = [];
multiSelect: any = [];
stringArray: any = [];
objectsArray: any = [];
resetOption: any;
config = {
    displayKey: "name",
    search: true
};
options = [
    {
        name: "Default",
    },
    {
        name: "Popularity",
    },
    {
        name: "Latest",
    },
    {
        name: "Price: low to high",
    },
    {
        name: "Price: high to low",
    }
];
searchChange($event) {
    console.log($event);
}
reset() {
    this.resetOption = [];
}
}
