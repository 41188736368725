

     <div class="row">
            <div class="column">
              <div class="card">
               <i class='fa fa-truck' style='font-size:48px;color:#FBDA6F'></i>
                 <h5>Free Shipping</h5>
                <p>Do not have to pay an additional shipping charge</p>
              </div>
            </div>
          
            <div class="column">
              <div class="card">
                <i class='fa fa-suitcase' style='font-size:48px;color:#FBDA6F'></i>
                <h5>Speedy Deliveries</h5>
                <p>Parcels dispatched ships within 24 hours <span style="visibility: hidden">sgfgfdgfg</span></p>
              </div>
            </div>
            
            <div class="column">
              <div class="card">
                <i class='fa fa-comment' style='font-size:48px;color:#FBDA6F'></i>
                <h5>Chat With Us</h5>
                <p>Customer experience experts at your service</p>
              </div>
            </div>
            
            <div class="column">
              <div class="card">
                <i class="fa fa-sheqel" style="font-size:48px;color:#FBDA6F"></i>
                <h5>Amazing Value</h5>
                <p>Competitive pricing on the best products<span style="visibility: hidden">sg</span></p>
              </div>
            </div>
          </div>
         