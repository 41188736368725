import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-faq-page',
    templateUrl: './faq-page.component.html',
    styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {

    constructor(private meta:Meta, public title:Title) { 
        this.title.setTitle("Frequently Asked Questions | Petvays.com");
        this.meta.addTags([
            { name: "robots", content: "index,follow"},
            { property: "og:type", content: "website"},
            { property: "og:url", content: "https://petvays.com/faqs"},
            { property: "og:title", content: "Frequently Asked Questions | Petvays.com"},
            { property: "og:image", content: "http://petvays.com/assets/img/faqs.jpg"},
            { property: "twitter:url", content: "https://petvays.com/faqs"},
            { property: "twitter:title", content: "Frequently Asked Questions | Petvays.com"},
            { property: "twitter:image", content: "http://petvays.com/assets/img/faqs.jpg"},
            { rel: 'canonical', href: 'https://petvays.com/faqs'},
        ])
    }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/faqs.jpg',
            // title: 'Frequently Asked Questions'
        }
    ]

}