import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-coming-soon-page',
    templateUrl: './coming-soon-page.component.html',
    styleUrls: ['./coming-soon-page.component.scss']
})
export class ComingSoonPageComponent implements OnInit {

  
    constructor() { }

    ngOnInit() {
        
    }

    pageTitle = [
        {
            bgImage: 'assets/img/property/website-banner.jpg',
            title: 'Shop Right Sidebar'
        }
    ]
}