<div class="container" style="margin-top: 0px;">
    <div class="section-title" *ngFor="let Content of sectionTitle;">
        <h2 style="padding: 18px;background-color:#fbda6f;font-family: 'Times New Roman', Times, serif;">{{Content.title}}</h2>
        <h5 style="font-family: 'Times New Roman', Times, serif;">{{Content.paragraph}}</h5>
    </div>

    <div class="products-slides">
        <owl-carousel-o [options]="productsSlidesOptions">
            <ng-template carouselSlide *ngFor="let Content of singleProductsBox;">
                <div class="single-products-box">
                    <div class="products-image" style="border: 1px solid rgba(0, 0, 0, 0.125);">
                        <a routerLink="/{{Content.detailsLink}}" class="d-block">
                            <img [src]="Content.mainImg" alt="image">
                        </a>
                      
                    </div>
                  
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>

    <div class="products-slides">
        <owl-carousel-o [options]="productsSlidesOptions">
            <ng-template carouselSlide *ngFor="let Content of singleProductsBox1;">
                <div class="single-products-box">
                    <div class="products-image" style="border: 1px solid rgba(0, 0, 0, 0.125); box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%);">
                        <a routerLink="/{{Content.detailsLink}}" class="d-block">
                            <img [src]="Content.mainImg" alt="image">
                        </a>
                     
                    </div>
                   
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>