import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser'; 
@Component({
    selector: 'app-shop-full-width-page-two',
    templateUrl: './shop-full-width-page-two.component.html',
    styleUrls: ['./shop-full-width-page-two.component.scss']
})
export class ShopFullWidthPageTwoComponent implements OnInit {
	jsonLD: any;
 
    constructor(private meta: Meta, private sanitizer: DomSanitizer, public title: Title) { 
		this.title.setTitle("Buy The Best Dog Collars Tag, Harnesses & Leashes | PetVays");
		this.meta.addTags([
			{ name: 'description', content: 'PetVays offers a variety of dog collars, leashes, and harnesses that are made from the highest quality materials to ensure that your pet is safe and comfortable.' },
			{ name: "keywords", content: "Buy Collars for Dog, Leashes for Dog, Harnesses for Dog, Buy Dog Accessories"},
			 // Meta robots
			{ name: "robots", content: "index,follow"},
			  // OG Tag Facebook
			{ property: "og:type", content: "website"},
			{ property: "og:url", content: "https://petvays.com/accessories"},
			{ property: "og:title", content: "Buy The Best Dog Collars Tag, Harnesses & Leashes | PetVays"},
			{ property: "og:description", content: "PetVays offers a variety of dog collars, leashes, and harnesses that are made from the highest quality materials to ensure that your pet is safe and comfortable."},
			{ property: "og:image", content: "http://petvays.com/assets/img/harness.png"},
			 // Twitter Card
			{property: "twitter:url", content: "https://petvays.com/accessories"},
			{property: "twitter:title", content: "Buy The Best Dog Collars Tag, Harnesses & Leashes | PetVays"},
			{property: "twitter:description", content: "PetVays offers a variety of dog collars, leashes, and harnesses that are made from the highest quality materials to ensure that your pet is safe and comfortable."},
			{property: "twitter:image", content: "http://petvays.com/assets/img/harness.png"},
			 // Canonical URL
			{rel: 'canonical', href: 'https://petvays.com/accessories'},
			

		  ]);  
	}

    ngOnInit(): void {

        const json = {
			"@context": "https://schema.org/", 
			"@type": "Product", 
			"name": "Collars",
			"image": "https://petvays.com/assets/img/accessories.jpg",
			"description": "PetVays offers a variety of dog collars, leashes, and harnesses that are made from the highest quality materials to ensure that your pet is safe and comfortable.",
			"brand": {
			  "@type": "Brand",
			  "name": "PetVays"
			},
			"offers": {
			  "@type": "Offer",
			  "url": "https://petvays.com/accessories",
			  "priceCurrency": "INR",
			  "price": "99",
			  "priceValidUntil": "2022-09-30",
			  "availability": "https://schema.org/InStock",
			  "itemCondition": "https://schema.org/NewCondition"
			},
			"aggregateRating": {
			  "@type": "AggregateRating",
			  "ratingValue": "4.4",
			  "ratingCount": "89"
			}
		  }
		  
		this.jsonLD = this.getSafeHTML(json);
    }
	getSafeHTML(value: {}) {
        const json = JSON.stringify(value, null, 2);
        const html = `<script type="application/ld+json">${json}</script>`;
        // Inject to inner html without Angular stripping out content
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    pageTitle = [
        {
            bgImage: 'assets/img/accessories.jpg',
            title: 'Collars, leashes & Harnesses'
        }
    ]
    productsSlidesOptions: OwlOptions = {
		loop: true,
		nav: false,
		dots: true,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 2
			},
			768: {
				items: 2
			},
			992: {
				items: 3
			},
			1200: {
				items: 3
			}
		}
    }


}








