<!-- Start Blog Area -->
<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title" *ngFor="let Content of sectionTitle;">
            <h2>{{Content.title}}</h2>
            <p>{{Content.paragraph}}</p>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6" *ngFor="let Content of singleBlogPost;">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/{{Content.link}}" class="d-block">
                            <!-- <img [src]="Content.img" alt="image"> -->
                            <video style="width: 100%; height: 280px;" class="library" controls preload="auto" data-config="some-js-object-here">
                                <source [src]="Content.img" type='video/mp4'>
                            </video>
                        </a>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->