import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { UserService } from 'src/app/services/user.service';
import { Firestore, collection, addDoc } from '@angular/fire/firestore';
@Component({
    selector: 'app-hometwo-banner',
    templateUrl: './hometwo-banner.component.html',
    styleUrls: ['./hometwo-banner.component.scss']
})
export class HometwoBannerComponent implements OnInit {
    contactForm!: FormGroup
    isFormSubmitted = false;
    isSuccess = false;
    activeSegment = 'buy'; // Default active segment is 'buy'
    showLocations = false;
    suggestions: string[] = ['Noida Sector 121', 'Noida Sector 115', 'Noida Sector 107'];
    constructor(private formBuilder: FormBuilder, private userService: UserService, private firestore:Firestore) { }

    ngOnInit(): void {
        this.initForm()

    }
    initForm() {
        this.contactForm = this.formBuilder.group({

            email: ['', [Validators.required, Validators.email]]

        });

    }

    mainBannerItem = [
        {
            image: 'assets/img/banner2.jpg',
            title: 'Yummy Rawhide Bones',
            paragraph: '100% Genuine Products and We provide best products for your pet. We are here to care🐶',
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'SHOP OUR PRODUCTS',
            buttonLink: 'collection-dog-food',

        },
        {
            image: 'assets/img/banner1.jpg',
            title: 'Yummy Mutton Munchies',
            paragraph: '100% Genuine Products and We provide best products for your pet. We are here to care🐶',
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'SHOP OUR PRODUCTS',
            buttonLink: 'collection-dog-food',

        },
        {
            image: 'assets/img/banner3.jpg',
            title: 'Natural Grooming Products',
            paragraph: '100% Genuine Products and We provide best products for your pet. We are here to care🐶',
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'SHOP OUR PRODUCTS',
            buttonLink: 'collection-dog-food',

        }
    ]
    mainBannerItem1 = [
        {
            image: 'assets/img/luxury.jpg',
            title: 'Luxury Apartments',
            paragraph: 'Sector 107, Noida🐶',
            price: '₹ 5.40Cr'
        },
        {
            image: 'assets/img/paradise-elevation.jpeg',
            title: 'Satyam Paradise',
            paragraph: 'Sector 121, Noida🐶',
            price: '₹ 29.50Lakh'

        },
        {
            image: 'assets/img/Lowrise Apartments.jpg',
            title: 'Lowrise Apartments',
            paragraph: 'Sector 121, Noida🐶',
            price: '₹ 60Lakh'

        }
    ]

    mainBannerItem2 = [
        {
            image: 'assets/img/prem-vastu-homes.jpg',
        },
        {
            image: 'assets/img/prem-vastu-homes1.jpg',

        },
        {
            image: 'assets/img/prem-vastu-homes2.jpg',

        }
    ]

    homeSlidesOptions: OwlOptions = {
        loop: true,
        nav: true,
        margin: 5,
        dots: false,
        autoplay: true,
        autoHeight: true,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        autoplayHoverPause: true,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>"
        ],
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 1
            },
            768: {
                items: 1
            },
            992: {
                items: 1
            },
            1200: {
                items: 1
            }
        }
    }

    productsSlidesOptions: OwlOptions = {
        loop: true,
        nav: false,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>"
        ],
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 2
            },
            992: {
                items: 1
            },
            1200: {
                items: 3
            }
        }
    }
    singleProductsBox2 = [
        {
            mainImg: 'assets/img/product-images/16.jpg',
            // title: 'Nutritious Food',
            // newPrice: '$150.00',
            // oldPrice: '',
            // detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/product-images/18.jpg',
            // title: 'Delicious Treats, Biscuits & Chews',
            // newPrice: '$199.00',
            // oldPrice: '$210.00',
            // sale: 'Sale!',
            // detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/product-images/4.jpg',
            // title: 'Easy Grooming',
            // newPrice: '$223.99',
            // oldPrice: '',
            // detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/product-images/17.jpg',
            // title: 'Modern Leather Soft',
            // newPrice: '$199.00',
            // oldPrice: '$210.00',
            // sale: 'Sale!',
            // detailsLink: 'simple-product'
        },
        {
            mainImg: 'assets/img/product-images/14.jpg',
            // title: 'Modern Leather Soft',
            // newPrice: '$199.00',
            // oldPrice: '$210.00',
            // sale: 'Sale!',
            // detailsLink: 'simple-product'
        }
    ]

    setActiveSegment(segment: string) {
        this.activeSegment = segment;
    }

    toggleLocations() {
        this.showLocations = !this.showLocations;
    }

    onInputChange(value: string) {
        this.showLocations = value.toLowerCase().includes('sector');
    }

    selectSuggestion(suggestion: string) {
        this.showLocations = false;
        // Handle selected suggestion
    }

    onSubmit() {
        this.isFormSubmitted = true;
        if(this.contactForm.valid){
          const collectionInstance =  collection(this.firestore, 'Support Email');
          addDoc(collectionInstance, this.contactForm.value).then(()=>{
            console.log("Email Submitted Successfully")
            this.isSuccess = true;
            this.isFormSubmitted = false;
            this.contactForm.reset()
          })
          .catch((error)=>{
            console.log(error)
          })
        }

    }
}