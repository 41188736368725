<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <!-- <h1 style="color: white;">{{Content.title}}</h1> -->
</div>


<div class="container ">
  <div class="card mb-5 mt-5" style="background-color: #f7f7f7; border: 1px solid #ccc; border-radius: 10px;">
    <div class="row">
      <!-- Image column -->
      <div class="col-md-3 mt-4 mx-3">
        <a href="assets/img/products/property5.jpg" class="popup-btn mt-2">
          <img src="assets/img/property/NBCC.jpg" alt="image">
        </a>
      </div>

      <!-- Text column -->
      <div class="col-md-8">
        <div class="card-body">
          <h3 class="card-title" style="font-size: 24px;">NBCC Aspire Heartbeat City</h3>
          <h4 class="card-subtitle mb-4" style="font-size: 18px;">Sector 121, Noida</h4>

          <!-- Price, Area, Construction Status -->
          <div class="row">
            <div class="col price border-top border-bottom mt-3 mb-2">
              <h4 class="new-price mt-2" style="font-size: 24px;">Rs 1.28 Cr</h4>
              <p style="font-size: 14px;">7,446/sq ft</p>
            </div>
            <div class="col price border-top border-bottom mt-3 mb-2">
              <h4 class="new-price mt-2 px-4" style="font-size: 24px;">1719</h4>
              <p style="font-size: 14px;">Area in sq ft</p>
            </div>
            <div class="col price border-top border-bottom mt-3 mb-2">
              <h4 class="new-price mt-2" style="font-size: 24px;">Ready to move</h4>
              <p style="font-size: 14px;">Construction status</p>
            </div>
          </div>

          <!-- Additional Details -->
          <div class="row mt-4">
            <div class="col" style="width: 120px;">
              <p style="border-right: 1px solid black;width: 150px;font-size: 14px;"><i class="fa fa-clock "></i> 0 - 1 years old</p>
            </div>
            <div class="col" style="width: 120px;">
              <p style="border-right: 1px solid black;width: 150px;font-size: 14px;"><i class="fa fa-bath"></i> 2 Bathrooms</p>
            </div>
            <div class="col">
              <p style="font-size: 14px;"><i class="fa fa-check-circle"></i>New</p>
            </div>
          </div>

          <!-- Description -->
          <p class="card-text mt-5" style="font-size: 16px;">A spacious 3 bhk multistorey apartment is available for sale in County Group County, Sector 121, Noida. It has a built-up area of 1719 sqft and is priced at Rs. 1.28 cr.</p>

          <div class="row mt-4">
            <div class="col">
              <i class="fa fa-heart"></i>
              <i class="fa fa-share mx-4"></i>
            </div>
            <div class="col">
              <button class="btn btn-danger mx-3" style="font-size: 15px;" [routerLink]="['/contact']">Connect Now</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container ">
  <div class="card mb-5 mt-5 card-hover-effect" style="background-color: #f7f7f7; border: 1px solid #ccc; border-radius: 10px;">
    <div class="row">
      <!-- Image column -->
      <div class="col-md-3 mt-5 mx-3">
        <a href="assets/img/prateek.jpeg" class="popup-btn ">
          <img src="assets/img/property/Capital.jpg" alt="image" >
        </a>
      </div>

      <!-- Text column -->
      <div class="col-md-8">
        <div class="card-body">
          <h3 class="card-title" style="font-size: 24px;">Capital Infra homes , Sector 121</h3>
          <h4 class="card-subtitle mb-4" style="font-size: 18px;">Sector 121, Noida</h4>

          <!-- Price, Area, Construction Status -->
          <div class="row">
            <div class="col price border-top border-bottom mt-3 mb-2">
              <h4 class="new-price mt-2" style="font-size: 24px;">Rs 18 Lakh</h4>
              <p style="font-size: 14px;">5,750/sq ft</p>
            </div>
            <div class="col price border-top border-bottom mt-3 mb-2">
              <h4 class="new-price mt-2 px-4" style="font-size: 24px;">750</h4>
              <p style="font-size: 14px;">Area in sq ft</p>
            </div>
            <div class="col price border-top border-bottom mt-3 mb-2">
              <h4 class="new-price mt-2" style="font-size: 24px;">Ready to move</h4>
              <p style="font-size: 14px;">Construction status</p>
            </div>
          </div>

          <!-- Additional Details -->
          <div class="row mt-4">
            <div class="col" style="width: 120px;">
              <p style="border-right: 1px solid black;width: 150px;font-size: 14px;"><i class="fa fa-clock"></i> 4 - 5 years old</p>
            </div>
            <div class="col" style="width: 120px;">
              <p style="border-right: 1px solid black;width: 150px;font-size: 14px;"><i class="fa fa-bath"></i> 1 Bathrooms</p>
            </div>
            <div class="col">
              <p style="font-size: 14px;"><i class="fa fa-check-circle"></i> New</p>
            </div>
          </div>

          <!-- Description -->
          <p class="card-text mt-5" style="font-size: 16px;">This spacious 1 bhk builder floor is available for sale and is located in Satyam Real Build Paradise, one of the most prestigious projects of Sector 121. It has an area of 750 sqft. The property is available at a price of Rs. 18.00 lac. It is a 2-year-old ready-to-move-in property.</p>

          <div class="row mt-4">
            <div class="col">
              <i class="fa fa-heart"></i>
              <i class="fa fa-share mx-4"></i>
            </div>
            <div class="col">
              <button class="btn btn-danger mx-3" style="font-size: 15px;" [routerLink]="['/contact']">Connect Now</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container border-bottom">
  <div class="card mb-5 mt-5 card-hover-effect" style="background-color: #f7f7f7; border: 1px solid #ccc; border-radius: 10px;">
   <div class="row">
      <!-- Image column -->
      <div class="col-md-3 mt-4 mx-3">
        <a href="assets/img/products/gaytri.jpeg" class="popup-btn">
          <img src="assets/img/property/Cleo.jpg" alt="image" class="card-img-top">
          <div class="img-overlay"></div>
        </a>
      </div>

      <!-- Text column -->
      <div class="col-md-8">
        <div class="card-body">
          <h3 class="card-title" style="font-size: 24px; font-weight: bold;">Cleo County, Sector 121</h3>
          <h4 class="card-subtitle mb-4" style="font-size: 18px; color: #555;">Sector 121, Noida</h4>

          <!-- Price, Area, Construction Status -->
          <div class="row">
            <div class="col price border-top border-bottom mt-3 mb-2">
              <h4 class="new-price mt-2" style="font-size: 24px; font-weight: bold;">Rs 29.99 Lakh</h4>
              <p style="font-size: 14px; color: #777;">6,750/sq ft</p>
            </div>
            <div class="col price border-top border-bottom mt-3 mb-2">
              <h4 class="new-price mt-2 px-4" style="font-size: 24px; font-weight: bold;">1150</h4>
              <p style="font-size: 14px; color: #777;">Area in sq ft</p>
            </div>
            <div class="col price border-top border-bottom mt-3 mb-2">
              <h4 class="new-price mt-2" style="font-size: 24px; font-weight: bold;">Ready to move</h4>
              <p style="font-size: 14px; color: #777;">Construction status</p>
            </div>
          </div>

          <!-- Additional Details -->
          <div class="row mt-4">
            <div class="col" style="width: 120px;">
              <p style="border-right: 1px solid black;width: 150px; font-size: 14px;"><i class="fa fa-clock"></i> 4 - 5 years old</p>
            </div>
            <div class="col" style="width: 120px;">
              <p style="border-right: 1px solid black;width: 150px; font-size: 14px;"><i class="fa fa-bath"></i> 2 Bathrooms</p>
            </div>
            <div class="col">
              <p style="font-size: 14px;"><i class="fa fa-check-circle"></i> New</p>
            </div>
          </div>

          <!-- Description -->
          <p class="card-text mt-5" style="font-size: 16px;">A 3 bhk property is available for sale in Creators Infrahomes Gayatri Vatika, Sector 121, Noida. It has an area of 1150 sqft. The property is available at a price of Rs. 29.99 lac. It is a semi-furnished property. It is a 4-year-old ready-to-move-in property.</p>

          <div class="row mt-4">
            <div class="col">
              <i class="fa fa-heart"></i>
              <i class="fa fa-share mx-4"></i>
            </div>
            <div class="col">
              <button class="btn btn-danger mx-3" style="font-size: 15px; border-radius: 20px;" [routerLink]="['/contact']">Connect Now</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- End Products Details Area -->