import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  constructor(private meta: Meta, public title:Title) { 
    this.title.setTitle("Terms of Service — Petvays.com");
    this.meta.addTags([
        { name: 'description', content: 'Welcome to Petvays.com Online Pet Food and Accessories Store.' },
        { name: "robots", content: "index,follow"},
        { property: "og:type", content: "website"},
        { property: "og:url", content: "https://petvays.com/terms-of-service"},
        { property: "og:title", content: "Terms of Service — Petvays.com"},
        { property: "og:description", content: "Welcome to Petvays.com Online Pet Food and Accessories Store."},
        { property: "og:image", content: "http://petvays.com/assets/img/images/termofservices.jpg"},
        {property: "twitter:url", content: "https://petvays.com/termofservise"},
        {property: "twitter:title", content: "Terms of Service — Petvays.com"},
        {property: "twitter:description", content: "Welcome to Petvays.com Online Pet Food and Accessories Store."},
        {property: "twitter:image", content: "http://petvays.com/assets/img/images/termofservices.jpg"},
        {rel: 'canonical', href: 'https://petvays.com/terms-of-service'},
    ])
  }

  ngOnInit(): void {
  }

  pageTitle = [
    {
        bgImage: 'assets/img/images/termofservices.jpg',
        title: 'Terms and Conditions'
    }
]


}
