import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-blog-grid',
    templateUrl: './blog-grid.component.html',
    styleUrls: ['./blog-grid.component.scss']
})
export class BlogGridComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/blog/banner-blog.jpg',
            title: 'Blog'
        }
    ]
    singleBlogPost = [
        {
            img: 'assets/img/blog/home-buying1.png',
            title: 'First Time Home Buyer: Things To Keep In Mind',
            paragraph: 'Buying your first home is a big step, and the rewards for every first-time homebuyer are huge. Especially as a first-time homebuyer, you are privy to opportunities and advantages not offered to repeat buyers. From tax breaks and government-backed loans to down payment assistance, the benefits of homeownership have never been more significant for new buyers. So before you search, evaluate your financial health to determine how much you can afford. Look at your income, expenses, debt, and credit score to ensure you’re stable enough to take on a first-time home buyer loan.',
            linkText: 'Read More',
            detailsLink: 'blog-details'
        },
        {
            img: 'assets/img/blog/flat.jpg',
            title: 'Is It Worth Investing In A Flat?',
            paragraph: 'Housing facilities are evolving with new developments making way for comfort and convenience everyday. The decision to buy a flat is a huge milestone for any family. The question is, is buying a flat a good investment? When you buy a home there are certain aspects that come along with it, like financial stability and independence. To buy a flat or not is a matter of debate, while some consider buying a flat a sound financial plan. In today’s fast pacing world making a wise financial decision is crucial for security and stability. When it comes to investing in real estate, investing in a flat or apartment comes as a rewarding choice.',
            linkText: 'Read More',
            detailsLink: 'blog-details'
        },
        {
            img: 'assets/img/blog/value-property.png',
            title: 'How to Maximise the Resale Value of Your Apartments',
            paragraph: 'As a savvy investor, you understand the importance of maximising your returns. Regarding apartments, one key aspect to consider is the resale value. Understanding what factors influence and how to enhance it can significantly impact your investment. In this article, we will delve into the secrets of unlocking the resale value of  your apartments, providing with valuable insights to optimize your returns.',
            linkText: 'Read More',
            detailsLink: 'blog-details'
        },
      
    ]

    blogGrid: number = 1;

}