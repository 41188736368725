import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-customer-service-page',
    templateUrl: './customer-service-page.component.html',
    styleUrls: ['./customer-service-page.component.scss']
})
export class CustomerServicePageComponent implements OnInit {

    constructor(private meta:Meta, public title:Title) { 
        this.title.setTitle("Privacy Policy — Petvays.com | Online Pet Food and Accessories Store");
        this.meta.addTags([
            { name: 'description', content: 'Welcome to our Privacy Policy your Privacy is Critically Important to us. Petvays.com Online Pet Food and Accessories Store.' },
            { name: "robots", content: "index,follow"},
            { property: "og:type", content: "website"},
            { property: "og:url", content: "https://petvays.com/privacy-policy"},
            { property: "og:title", content: "Privacy Policy — Petvays.com | Online Pet Food and Accessories Store"},
            { property: "og:description", content: "Welcome to our Privacy Policy your Privacy is Critically Important to us. Petvays.com Online Pet Food and Accessories Store."},
            { property: "og:image", content: "http://petvays.com/assets/img/images/Privacy&Policy.jpg"},
            {property: "twitter:url", content: "https://petvays.com/privacy-policy"},
            {property: "twitter:title", content: "Privacy Policy — Petvays.com | Online Pet Food and Accessories Store"},
            {property: "twitter:description", content: "Welcome to our Privacy Policy your Privacy is Critically Important to us. Petvays.com Online Pet Food and Accessories Store."},
            {property: "twitter:image", content: "http://petvays.com/assets/img/images/Privacy&Policy.jpg"},
            {rel: 'canonical', href: 'https://petvays.com/privacy-policy'},
        ])
    }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/images/Privacy&Policy.jpg',
            // title: 'Privacy Policy'
        }
    ]

}