import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { AbstractControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { UserService } from './services/user.service';
declare let $: any;
import { Firestore, collection, addDoc } from '@angular/fire/firestore';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    location: any;
    routerSubscription: any;
    contactForm: FormGroup;
    isSuccess: boolean = false;
    isFormSubmitted: boolean = false;
    @ViewChild('popupTemplate') popupTemplate: any;
    isFormClosed: boolean = false;
    showPopup: boolean = false;
    constructor(private router: Router, private formBuilder: FormBuilder, private userService:UserService, private firestore:Firestore) {
    }

    ngOnInit() {
        this.recallJsFuntions();
       this.initForm()
        setTimeout(() => {
            this.showPopup = true;
        }, 8000);
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/main.js');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
        console.log(this.location)
    }

    closeForm(): void {
        this.isFormClosed = true;
    }
    // showPopup(): void {
    //     const popupContainer = document.createElement('div');
    //     popupContainer.innerHTML = this.popupTemplate.nativeElement.innerHTML;
    //     document.body.appendChild(popupContainer);
    // }
    initForm() {
        this.contactForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
            email: ['', [Validators.required, Validators.email]],
            mobile: ['', [Validators.required, this.mobileValidator]],
            msg: [''],
            address: ['', [Validators.required]]
        });
    }
    onSubmit() {
        this.isFormSubmitted = true;
        if(this.contactForm.valid){
          const collectionInstance =  collection(this.firestore, 'users');
          addDoc(collectionInstance, this.contactForm.value).then(()=>{
            console.log("Form Submitted Successfully")
            this.isSuccess = true;
            this.isFormSubmitted = false;
            this.contactForm.reset()
          })
          .catch((error)=>{
            console.log(error)
          })
        }

    }
    // Custom validator function for mobile field
   mobileValidator(control: AbstractControl): ValidationErrors | null {
    const phoneNumberPattern = /^\d{10}$/;
  
    if (!phoneNumberPattern.test(control.value)) {
      return { 'invalidMobile': true };
    }
  
    return null;
   }
}