<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1 style="color: white">{{Content.title}}</h1>
    <!-- <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{Content.title}}</li>
    </ul> -->
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area pt-100 pb-70">
    <div class="container">
        <div class="about-content" *ngFor="let Content of aboutContent;">
            <h3>{{Content.subTitle}}</h3>
            <h2>{{Content.title}}</h2>
            <p style="text-align: justify;">{{Content.paragraph}}</p>

            <!-- <div class="content" *ngFor="let Text of Content.content">
                <h3>{{Text.title}}</h3>
                <p style="text-align: justify;">{{Text.paragraph}}</p>
            </div> -->
        </div>

       
    </div>
</section>
<!-- End About Area -->

<!-- <app-why-choose-us></app-why-choose-us>

<app-video></app-video>

<app-testimonials></app-testimonials>

<app-facility-style-one></app-facility-style-one> -->