<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area mt-0" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
</div>

<div class="container" style="margin-top: 0px;">
    <div class="section-title" *ngFor="let Content of pageTitle;">
        <h2 style="text-align:center;font-family: 'Times New Roman', Times, serif;margin-top: 10px;">{{Content.title}}</h2>
    </div>
   
<div class="row">
    <div class="column">
        <img routerLink="/harness" src="assets/img/harness.png" alt="harness">
        <h3 style="text-align:center;font-family: 'Times New Roman', Times, serif;">Harness For Dogs-Red</h3>
    </div>
    
    <div class="column">
        <img routerLink="/leash" src="assets/img/leash.png" alt="leash">
        <h3 style="text-align:center;font-family: 'Times New Roman', Times, serif;">Leash For Dogs-Red</h3>
    </div>
    <div class="column"> 
          <img routerLink="/collar" src="assets/img/collar-red.png" alt="collar">
          <h3 style="text-align:center;font-family: 'Times New Roman', Times, serif;">Collar For Dogs-Red</h3>
      </div>
</div></div>
