import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser'; 
@Component({
    selector: 'app-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {

    constructor(private meta: Meta, public title: Title) { 
	}

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/about-us.jpg',
            // title: 'About Us'
        }
    ]

    aboutContent = [
        {
            subTitle: `ABOUT US`,
            //title: `We've Been Thriving in 37 Years In This Area`,
            paragraph: `Launched in 2018, premvastu.com, India’s No. 1 property portal, deals with every aspect of the consumers’ needs in the real estate industry. It is an online forum where buyers, sellers and brokers/agents can exchange information about real estate properties quickly, effectively and inexpensively. At premvastu.com, you can advertise a property, search for a property, browse through properties, build your own property microsite, and keep yourself updated with the latest news and trends making headlines in the realty sector.`,
            content: [
                {
                    //title: `Our Goal`,
                    //paragraph: `It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
                },
                {
                    title: `Our Mission`,
                    paragraph: `Our mission is to deliver high quality and low-cost one stop platform for ecommerce, promotions, inventory and home delivery needs to our consumers, retailers, businesses and wholesalers.`
                },
                {
                    title: `Our Vision`,
                    paragraph: `Our vision is to bring all businesses and every individual of India on an ecommerce and mobile one stop commerce platform allowing them to focus on their primary needs, business activities, while reducing spam, time investments, cost in the safe & secure and trust-worthy SPOT environment and marketplace.`
                }
            ]
        }
    ]
    // aboutImage = [
    //     {
    //         img: 'assets/img/about-img1.jpg'
    //     },
    //     {
    //         img: 'assets/img/about-img2.jpg'
    //     }
    // ]

}