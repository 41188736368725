<router-outlet></router-outlet>
<app-footer *ngIf="!(location == '/coming-soon') && !(location == '/search-result')"></app-footer>
<ngx-scrolltop *ngIf="!(location == '/coming-soon')"></ngx-scrolltop>
<div class="container">
    <!-- Your main content here -->

    <!-- Popup Template -->
    <div *ngIf="showPopup" class="popup" [hidden]="isFormClosed">
        <div class="contact-form">
            <h2>Welcome to Our PremVastu Website!</h2>
            <p>Please fill out the form below to get in touch with us.</p>
            <button class="close-button" (click)="closeForm()">&times;</button>
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <input formControlName="name" type="text" class="form-control" placeholder="Your name">
                            <div
                                *ngIf="contactForm.get('name').invalid && (contactForm.get('name').dirty || contactForm.get('name').touched)">
                                <div *ngIf="contactForm.get('name').errors.required">Name is required.</div>
                                <div *ngIf="contactForm.get('name').errors.minlength">Name must be at least 3 characters
                                    long.</div>
                                <div *ngIf="contactForm.get('name').errors.maxlength">Name can't be longer than 50
                                    characters.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <input formControlName="email" type="text" class="form-control"
                                placeholder="Your email">
                            <div
                                *ngIf="contactForm.get('email').invalid && (contactForm.get('email').dirty || contactForm.get('email').touched)">
                                <div *ngIf="contactForm.get('email').errors.required">Email Address is required.</div>
                                <div *ngIf="contactForm.get('email').errors.email">Invalid email format.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group mb-3">
                          <input formControlName="address" type="text" class="form-control" placeholder="Address">
                          <div *ngIf="contactForm.get('address').invalid && (contactForm.get('address').dirty || contactForm.get('address').touched)">
                            <div *ngIf="contactForm.get('address').errors.required">Address is required.</div>
                          </div>
                        </div>
                      </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group mb-3">
                          <input formControlName="mobile" type="number" class="form-control" placeholder="Your phone number">
                          <div *ngIf="contactForm.get('mobile').invalid && (contactForm.get('mobile').dirty || contactForm.get('mobile').touched)">
                            <div *ngIf="contactForm.get('mobile').errors.required">Phone number is required.</div>
                            <div *ngIf="contactForm.get('mobile').errors.invalidMobile">Invalid phone number. Please enter a 10-digit number.</div>
                          </div>
                        </div>
                      </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group mb-3">
                            <textarea formControlName="msg" cols="30" rows="6" class="form-control"
                                placeholder="Write your message..."></textarea>
                            <div
                                *ngIf="contactForm.get('msg').invalid && (contactForm.get('msg').dirty || contactForm.get('msg').touched)">
                                <div *ngIf="contactForm.get('msg').errors.required">Message is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-success" *ngIf="isSuccess">
                        Form submitted successfully!
                    </div>
                    <div class="alert alert-danger" *ngIf="isFormSubmitted && !contactForm.valid">
                        Please fill in all the required fields.
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn" [disabled]="contactForm.invalid">
                            <i class="bx bx-paper-plane"></i> Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>