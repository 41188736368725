<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <!-- <h1 style="color: white;">{{Content.title}}</h1> -->
</div>
<!-- End Page Title Area -->

<!-- Start Products Details Area -->



<!-- <div class="container border-bottom">
    <div class="row">
       
        <div class="col-md-4 mb-5 mt-5">
            <a href="assets/img/products/property5.jpg" class="popup-btn">
                <img src="assets/img/property9.jpg" alt="image">
            </a>
        </div>

        <div class="col-md-8 mb-5 mt-5">
            <div class="products-details-desc products-details-desc-sticky">
                <h3>3 BHK Apartment in Assotech Windsor Court</h3>
                <h4>Sector 121, Noida</h4>

               
                <div class="row">
                    <div class="col price border-top border-bottom mt-3 mb-2">
                        <h4 class="new-price mt-2">Rs 1.11 Cr</h4>
                        <p>6,750/sq ft</p>
                    </div>
                    <div class="col price border-top border-bottom mt-3 mb-2">
                        <h4 class="new-price mt-2 px-4">1645</h4>
                        <p>Area in sq ft</p>
                    </div>
                    <div class="col price border-top border-bottom mt-3 mb-2">
                        <h4 class="new-price mt-2">Ready to move</h4>
                        <p>Construction status</p>
                    </div>
                </div>

               
                <div class="row mt-4">
                    <div class="col" style="width: 120px;">
                        <p style="border-right: 1px solid black;width: 150px;">4 - 5 years old</p>
                    </div>
                    <div class="col" style="width: 120px;">
                        <p style="border-right: 1px solid black;width: 150px;">3 Bathrooms</p>
                    </div>
                    <div class="col">
                        <p>New</p>
                    </div>
                </div>


                <p class="mt-5">It’s a 3 bhk multistorey apartment situated in Assotech Limited Windsor Court,
                    Sector 78, Noida. It has a salable area of 1645 sqft and is available at a price of Rs.
                    6,750 per sqft. ,...</p>

            </div>
            <div class="row mt-4">
                <div class="col">
                    <i class="fa fa-heart"></i>
                    <i class="fa fa-share mx-4"></i>
                </div>
                <div class="col">
                    <span class="text-danger">view Phone</span>
                    <button class="btn btn-danger mx-3" style="font-size: 15px;">conncet Now</button>
                </div>
            </div>
        </div>

    </div>
</div> -->

<div class="container border-bottom ">
    <div class="card mb-5 mt-5">
      <div class="row">
        <!-- Image column -->
        <div class="col-md-3 mt-4 mx-3">
          <a href="" class="popup-btn">
            <img src="assets/img/property8.jpg" alt="image">
          </a>
        </div>
  
        <!-- Text column -->
        <div class="col-md-8">
          <div class="card-body">
            <h3 class="card-title">4 BHK Apartment in Sunworld Vanalika</h3>
            <h4 class="card-subtitle mb-4">Sector 107, Noida</h4>
  
            <!-- Price, Area, Construction Status -->
            <div class="row">
              <div class="col price border-top border-bottom mt-3 mb-2">
                <h4 class="new-price mt-2">Rs 22,000</h4>
                <!-- <p>7,446/sq ft</p> -->
              </div>
              <div class="col price border-top border-bottom mt-3 mb-2">
                <h4 class="new-price mt-2 px-4">1730</h4>
                <p>Area in sq ft</p>
              </div>
              <div class="col price border-top border-bottom mt-3 mb-2">
                <h4 class="new-price mt-2">Ready to move</h4>
                <p>Construction status</p>
              </div>
            </div>
  
            <!-- Additional Details -->
            <div class="row mt-4">
              <div class="col" style="width: 120px;">
                <p style="border-right: 1px solid black;width: 150px;">No Deposit</p>
              </div>
              <div class="col" style="width: 120px;">
                <p style="border-right: 1px solid black;width: 150px;">4 Bathrooms</p>
              </div>
              <div class="col">
                <p>New</p>
              </div>
            </div>
  
            <!-- Description -->
            <p class="card-text mt-5">It’s a 4 bhk multistorey apartment situated in Sunworld Vanalika, Sector 107, Noida. It has area of 1730 sqft and is available at a rent of Rs. 22,000. It is facing southeast direction. This residential property is ready-to-move-in.</p>
  
            <div class="row mt-4">
              <div class="col">
                <i class="fa fa-heart"></i>
                <i class="fa fa-share mx-4"></i>
              </div>
              <div class="col">
                <button class="btn btn-danger mx-3" style="font-size: 15px;" [routerLink]="['/contact']">connect Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  


  <div class="container border-bottom ">
    <div class="card mb-5 mt-5">
      <div class="row">
        <!-- Image column -->
        <div class="col-md-3 mt-4 mx-3">
          <a href="assets/img/great.jpeg" class="popup-btn">
            <img src="assets/img/red.jpg" alt="image">
          </a>
        </div>
  
        <!-- Text column -->
        <div class="col-md-8">
          <div class="card-body">
            <h3 class="card-title">4 BHK Apartment in Great Value Sharanam</h3>
            <h4 class="card-subtitle mb-4">Sector 107, Noida</h4>
  
            <!-- Price, Area, Construction Status -->
            <div class="row">
              <div class="col price border-top border-bottom mt-3 mb-2">
                <h4 class="new-price mt-2">Rs 40,000</h4>
                <!-- <p>5,750/sq ft</p> -->
              </div>
              <div class="col price border-top border-bottom mt-3 mb-2">
                <h4 class="new-price mt-2 px-4">2289</h4>
                <p>Area in sq ft</p>
              </div>
              <div class="col price border-top border-bottom mt-3 mb-2">
                <h4 class="new-price mt-2">Ready to move</h4>
                <p>Construction status</p>
              </div>
            </div>
  
            <!-- Additional Details -->
            <div class="row mt-4">
              <div class="col" style="width: 120px;">
                <p style="border-right: 1px solid black;width: 150px;">No deposit</p>
              </div>
              <div class="col" style="width: 120px;">
                <p style="border-right: 1px solid black;width: 150px;">1 Bathrooms</p>
              </div>
              <div class="col">
                <p>New</p>
              </div>
            </div>
  
            <!-- Description -->
            <p class="card-text mt-5">This spacious 1 bhk builder floor is available for sale and is located in Satyam Real Build Paradise, one of the most prestigious projects of Sector 121. It has an area of 750 sqft . The property is available at a price of Rs. 18.00 lac . It is a 2 year old ready-to-move-in property.</p>
  
            <div class="row mt-4">
              <div class="col">
                <i class="fa fa-heart"></i>
                <i class="fa fa-share mx-4"></i>
              </div>
              <div class="col">
                <button class="btn btn-danger mx-3" style="font-size: 15px;" [routerLink]="['/contact']">connect Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container border-bottom ">
    <div class="card mb-5 mt-5">
      <div class="row">
        <!-- Image column -->
        <div class="col-md-3 mt-4 mx-3">
          <a href="assets/img/products/prateek.jpeg" class="popup-btn">
            <img src="assets/img/prateek.jpeg" alt="image">
          </a>
        </div>
  
        <!-- Text column -->
        <div class="col-md-8">
          <div class="card-body">
            <h3 class="card-title">3 BHK Apartment in Prateek Edifice</h3>
            <h4 class="card-subtitle mb-4">Sector 107, Noida</h4>
  
            <!-- Price, Area, Construction Status -->
            <div class="row">
              <div class="col price border-top border-bottom mt-3 mb-2">
                <h4 class="new-price mt-2">Rs 40,000</h4>
                <!-- <p>6,750/sq ft</p> -->
              </div>
              <div class="col price border-top border-bottom mt-3 mb-2">
                <h4 class="new-price mt-2 px-4">2070</h4>
                <p>Area in sq ft</p>
              </div>
              <div class="col price border-top border-bottom mt-3 mb-2">
                <h4 class="new-price mt-2">Ready to move</h4>
                <p>Construction status</p>
              </div>
            </div>
  
            <!-- Additional Details -->
            <div class="row mt-4">
              <div class="col" style="width: 120px;">
                <p style="border-right: 1px solid black;width: 150px;">Rs 80,000 Deposit</p>
              </div>
              <div class="col" style="width: 120px;">
                <p style="border-right: 1px solid black;width: 150px;">3 Bathrooms</p>
              </div>
              <div class="col">
                <p>New</p>
              </div>
            </div>
  
            <!-- Description -->
            <p class="card-text mt-5">It has an area of 2070 sqft . The property is available at a monthly rental of Rs. 40,000 . It has 3 wardrobe, 1 gas connection, 5 ac and 1 modular kitchen. It is located on the 12th floor(out of a total of 29 floor(s)). It has children's play area.</p>
  
            <div class="row mt-4">
              <div class="col">
                <i class="fa fa-heart"></i>
                <i class="fa fa-share mx-4"></i>
              </div>
              <div class="col">
                <button class="btn btn-danger mx-3" style="font-size: 15px;" [routerLink]="['/contact']">connect Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


<!-- End Products Details Area -->