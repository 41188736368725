import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { promise } from 'protractor';
import { error } from 'console';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseApiUrl = "";
  constructor(private http: HttpClient) { }
  contactUs(formData: any) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baseApiUrl + 'contact_us', formData).subscribe((resp: any) => {
        resolve(resp)
      }, error => {
        reject(error)
      })
    })
  }
 
}
