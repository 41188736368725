<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area mt-0" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <!-- <h1>{{Content.title}}</h1>
    <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{Content.title}}</li>
    </ul> -->
</div>
<div class="container" style="margin-top: 0px;">
    <div class="section-title" *ngFor="let Content of pageTitle;">
        <h2 style="text-align:center;font-family: 'Times New Roman', Times, serif;margin-top: 10px;">{{Content.title}}</h2>
        <!-- <h5 style="font-family: 'Times New Roman', Times, serif;">{{Content.paragraph}}</h5> -->
    </div>
    <div class="products-slides">
        <owl-carousel-o [options]="productsSlidesOptions">
            <ng-template carouselSlide *ngFor="let Content of singleProductsBox;">
                <div class="single-products-box">
                    <div class="products-image">
                        <a class="d-block">
                            <img [src]="Content.mainImg" alt="image">
                        </a>
                    </div>
                   
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
   
</div>
