import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { APIS } from 'src/app/config';
import { PetvaysServiceService } from 'src/app/petvays-service.service';

@Component({
    selector: 'app-shop-full-width-page-one',
    templateUrl: './shop-full-width-page-one.component.html',
    styleUrls: ['./shop-full-width-page-one.component.scss']
})
export class ShopFullWidthPageOneComponent implements OnInit {
   
    constructor(){}
    ngOnInit(): void {
    }
    
    pageTitle = [
        {
            bgImage: 'assets/img/aba.jpg',
            title: 'Property of Noida'
        }
    ]


}