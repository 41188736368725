import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-my-account-page',
    templateUrl: './my-account-page.component.html',
    styleUrls: ['./my-account-page.component.scss']
})
export class MyAccountPageComponent implements OnInit {
formGroup:FormGroup
    api: any;
    constructor() { }

    ngOnInit(): void {
        this.initForm();
    }

    pageTitle = [
        {
            bgImage: 'assets/img/images/login_banner.jpg',
            // title: 'Profile Authentication'
            
        }
    ]
    initForm(){
        this.formGroup=new FormGroup({
            email:new FormControl("",[Validators.required]),
           password:new FormControl("",[Validators.required]),
        })
    }
    loginProcess(){
        if(this.formGroup.valid){
            this.api.login(this.formGroup.value).subscribe(result=>{
                if(result.success){
                    console.log(result)
                    alert(result.message)
                }
                else{
                    alert(result.message)
                }
            })
        }
    }
}