
  <section id="responsive-images-breakpoints">
    <div class="container">
        <h2 style="font-size: 40px">For the love of <span style="color:#FBDA6F">Pets</span>
        </h2>
   <img class="breakpoint" src="assets\img\Product Icon.png" width="960" height="640" />
    <img class="breakpoint" src="assets\img\Product Icon1.png" width="960" height="640" />
    <img class="breakpoint" src="assets\img\Product Icon2.png" width="960" height="640" />
    <img class="breakpoint" src="assets\img\Product Icon4.png" width="960" height="640" />
    </div>
  </section>
  <!-- <h2 style="font-size: 40px; text-align: center; margin-top: 30px;">For the love of Dogs</h2> -->
