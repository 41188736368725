import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class FooterComponent implements OnInit {

    location: any;
    bgClass: any;
    private pdfUrl="https://www.cleocounty.com/upload/media/e-brochure/Cleo-e-brochure-17082018.pdf";
    constructor(
        private router: Router,
        location: Location
    ) {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationEnd ) {
                this.location = this.router.url;
                if (this.location == '/' || this.location == '/index-2' || this.location == '/index-3' || this.location == '/about'){
                    this.bgClass = '';
                }  else {
                    this.bgClass = 'bg-f5f5f5';
                }
            }
        });
    }

    ngOnInit(): void {
    }
    downloadPdf(){
        const link=document.createElement('a');
        link.href=this.pdfUrl;
        link.target='_blank';
        link.download='Cleo-e-brochure.pdf'
        link.click()

    }
}