import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { APIS } from 'src/app/config';
import { PetvaysServiceService } from 'src/app/petvays-service.service';

@Component({
    selector: 'app-simple-product-page',
    templateUrl: './simple-product-page.component.html',
    styleUrls: ['./simple-product-page.component.scss']
})
export class SimpleProductPageComponent implements OnInit {
   productID:any
   product_Id_Details:any
    constructor(private router:Router, private route:ActivatedRoute, private activatedRoute:ActivatedRoute, private http: PetvaysServiceService) { 
         console.log(this.router.getCurrentNavigation().extras.state);
    }

    ngOnInit(): void {
        // this.resetOption = [this.options[0]];
    }

    pageTitle = [
        {
            bgImage: 'assets/img/property/county.jpg',
            title: 'Shop Left Sidebar'
        }
    ]

   

}