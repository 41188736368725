import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
    selector: 'app-gallery-page',
    templateUrl: './gallery-page.component.html',
    styleUrls: ['./gallery-page.component.scss']
})
export class GalleryPageComponent implements OnInit {

    constructor(private meta: Meta, public title:Title) { 
        this.title.setTitle("PetVays - Gallery");
        this.meta.addTags([
            { name: 'description', content: 'Check Hot Deal, Offers, Events, and PetVays New Launch Products.' },
            { name: "robots", content: "index,follow"},
            { property: "og:type", content: "website"},
            { property: "og:url", content: "https://petvays.com/gallery"},
            { property: "og:title", content: "PetVays - Gallery"},
            { property: "og:description", content: "Check Hot Deal, Offers, Events, and PetVays New Launch Products."},
            { property: "og:image", content: "http://petvays.com/assets/img/harness.png"},
            {property: "twitter:url", content: "https://petvays.com/gallery"},
            {property: "twitter:title", content: "PetVays - Gallery"},
            {property: "twitter:description", content: "Check Hot Deal, Offers, Events, and PetVays New Launch Products."},
            {property: "twitter:image", content: "http://petvays.com/assets/img/Shampooo.jpg"},
            {rel: 'canonical', href: 'https://petvays.com/gallery'},
        ])
    }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            bgImage: 'assets/img/gallerymedia.jpg',
            // title: 'Gallery'
        }
    ]
    singleGalleryItem = [
        {
            img: 'assets/img/gallery/delicious-treats.jpg'
        },
        {
            img: 'assets/img/gallery/meal-time.jpg'
        },
        {
            img: 'assets/img/gallery/natural-perfume.jpg'
        },
        {
            img: 'assets/img/gallery/pawfectbestfriend2.jpg'
        },
        {
            img: 'assets/img/gallery/pawfectbestfriend.jpg'
        },
        {
            img: 'assets/img/gallery/Rawhide.jpg'
        },
        {
            img: 'assets/img/gallery/Rewards.jpg'
        },
        {
            img: 'assets/img/gallery/Shampooo.jpg'
        },
        {
            img: 'assets/img/gallery/tasty-treats.jpg'
        },
         {
            img: 'assets/img/gallery/yummy-munchies.jpg'
        }
    ]

}