import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-deal-in-this-week',
    templateUrl: './deal-in-this-week.component.html',
    styleUrls: ['./deal-in-this-week.component.scss']
})
export class DealInThisWeekComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    dealOfTheWeekContent = [
        {
            title: 'Deal In This Week',
            paragraph: 'Dog Training Books, Socialize Dog Training & Puppy Training Service Provider offered by Best Dogs Deals from Delhi NCR, Noida, India.',
            discountPercent: '50% OFF',
            buttonIcon: 'bx bx-shopping-bag',
            buttonText: 'SHOP NOW',
            buttonLink: 'shop-full-width-1'
        }
    ]
    dealOfTheWeekImage = [
        {
            img: 'assets/img/group-portrait-adorable-puppies_53876-64777.jpg'
        }
    ]

}