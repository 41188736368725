<div class="container">
    <div class="section-title" *ngFor="let Content of sectionTitle;">
      <h2 style="background-color: black; color: white; padding: 12px;border-radius: 10px;">{{Content.title}}</h2>
        <h5>{{Content.paragraph}}</h5>
    </div>
    <div class="section-title" *ngFor="let Content of sectionTitle1;">
        <h5 style="font-size: 30px;">{{Content.title}}</h5>
        <h6>{{Content.paragraph}}</h6>
    </div>

    <div class="products-slides">
        <owl-carousel-o [options]="productsSlidesOptions">
            <ng-template carouselSlide *ngFor="let Content of singleProductsBox1;">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/{{Content.detailsLink}}" class="d-block">
                            <img [src]="Content.mainImg" alt="image">
                        </a>
                       
                    </div>
                  
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="section-title" *ngFor="let Content of sectionTitleM;">
        <h5 style="font-size: 30px;">{{Content.title}}</h5>
        <h5>{{Content.paragraph}}</h5>
    </div>

    <div class="products-slides">
        <owl-carousel-o [options]="productsSlidesOptions">
            <ng-template carouselSlide *ngFor="let Content of singleProductsBox;">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/{{Content.detailsLink}}" class="d-block">
                            <img [src]="Content.mainImg" alt="image">
                        </a>
                       
                    </div>
                   
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>

  <div class="section-title" *ngFor="let Content of sectionTitle2;">
        <h5 style="font-size: 30px;">{{Content.title}} (<span style="font-size: 25px">{{Content.paragraph}}</span>)</h5>
    </div>

    <div class="products-slides">
        <owl-carousel-o [options]="productsSlidesOptions">
            <ng-template carouselSlide *ngFor="let Content of singleProductsBox2;">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/{{Content.detailsLink}}" class="d-block">
                            <img [src]="Content.mainImg" alt="image">
                        </a>
                        
                    </div>
                   
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    
    
</div>