<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1 style="color: white">{{Content.title}}</h1>
    <!-- <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{Content.title}}</li>
    </ul> -->
</div>

<!-- End Page Title Area -->

<!-- Start About Area -->
<section class="about-area pt-100 pb-70">
    <div class="container">
        <div class="about-content" *ngFor="let Content of aboutContent;">
            <h3 style="font-family: 'Times New Roman', Times, serif;">{{Content.subTitle}}</h3>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6" *ngFor="let Image of aboutImage;">
                    <div class="about-image text-center">
                        <img [src]="Image.img" alt="image">
                    </div>
                </div>
            </div>
            <h5>{{Content.title}}</h5>
            <p style="text-align: justify;font-family: 'Times New Roman', Times, serif;">{{Content.paragraph}}</p>

            <div class="content" *ngFor="let Text of Content.content">
                <h5 style="font-family: 'Times New Roman', Times, serif;">{{Text.title}}</h5>
                <p style="text-align: justify;font-family: 'Times New Roman', Times, serif;">{{Text.paragraph}}</p>
            </div>
        </div>

       
    </div>
</section>