
<!-- End Footer Area -->

<!-- Start Sidebar Modal Area -->
<!-- Footer -->
<footer class="footer">
    <div class="row">
      <div class="col d-flex">
        <h4>INFORMATION</h4>
        <a href="about">About us</a>
        <a href="contact">Contact Us</a>
        <a href="">Term & Conditions</a>
        <a class="btn-1" style="cursor: pointer;" (click)="downloadPdf()">Download PDF</a>
      </div>
      <div class="col d-flex">
        <h4>USEFUL LINK</h4>
        <a href="">Customer Services</a>
        <a href="">Promotion</a>
        <a href="">Top Brands</a>
      </div>
      <div class="col d-flex">
        <span><i class="bx bxl-facebook-square"></i></span>
        <span><i class="bx bxl-instagram-alt"></i></span>
        <span><i class="bx bxl-github"></i></span>
        <span><i class="bx bxl-twitter"></i></span>
        <span><i class="bx bxl-pinterest"></i></span>
      </div>
    </div>

  </footer> 
<!-- End Sidebar Modal Area -->

<!-- Start QuickView Modal Area -->
<!-- End QuickView Modal Area -->

<!-- Start Sidebar Modal Area -->
 
<!-- End Sidebar Modal Area -->