import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PetvaysServiceService } from 'src/app/petvays-service.service';
import { SearchService } from 'src/app/search.service';

@Component({
    selector: 'app-navbar-style-two',
    templateUrl: './navbar-style-two.component.html',
    styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {
    productList: any;
    searchCard:boolean = false;
    constructor(private router:Router, private searchService:SearchService, private $petvaysService:PetvaysServiceService) { }

    ngOnInit(): void {
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    // searchProduct(event:any){
    //     console.log(event,'event..')
    //     if(event == ''){
    //         this.searchCard  = false; 
    //     } else {
    //         this.searchCard = true;
    //     }
    
    //     let search = {search: event, "city": "Delhi NCR", "limit": 20, "offset": 0}
    
    //     this.$petvaysService.getProduct(search).subscribe((res:any) => {
    //       this.productList = res;
    //       this.searchService.sendProductData(this.productList)
    //     })
    //   }


    //   getProducts(data){
    //     this.router.navigate(
    //         ['/search-result'],
    //         { queryParams: { q: data } }
    //       )

    //       let search = {search: data, "city": "Delhi NCR", "limit": 20, "offset": 0}
    
    // this.$petvaysService.getProduct(search).subscribe((res:any) => {
    //   this.productList = res;
    // })
    

    //       this.searchService.sendProductData(this.productList)

    //   }
      
    
    // back(){
    //     this.router.navigate(['/search-product']);
    // }
}