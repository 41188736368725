<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <h1>{{Content.title}}</h1>
    <!-- <ul>
        <li><a routerLink="/">Home</a></li>
        <li>{{Content.title}}</li>
    </ul> -->
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<section class="blog-details-area ptb-100" style="margin-top: 24px">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="assets/img/dog.png" alt="image">
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li>By: <a routerLink="/blog-grid">Kapil Bhardwaj</a></li>
                                <li>Last Updated: September 9, 2022</li>
                            </ul>
                        </div>
                        <p>If you love your dog, the last thing you want to do is hurt them. Unfortunately, dog food manufacturers aren’t always honest about what’s in their products and some dogs can suffer from food allergies or intolerances that cause discomfort, pain, or serious health problems if left untreated. By following these tips on how to pick the right food for your dog’s diet, you can ensure they won’t experience any of these problems while still getting the nutrition they need to grow strong and healthy with age.</p>
                        <blockquote class="wp-block-quote">
                            <p>Best Pet Food Brand has been at this game for over 30 years!</p>
                            
                        </blockquote>
                        <p>A lot of people think that dog food is made of low-quality, dry kibble bits, with a small amount of water, byproduct grease or vegetable oils mixed in and all sorts of weird animal byproducts like beaks or feathers. But if you buy your pet food online (and they deserve it!), you will see that these foods are much more likely to contain just animal protein, veggies and grains which is what their bodies need. <button routerLink="/blogs/is-your-dogs-diet-making-them-sick" class="button button3"><b>Read More</b></button></p>
        
                        
                       
                    </div>

                    <div class="article-footer">
                        <div class="article-share">
                            <ul class="social">
                                <li><span>Share:</span></li>
                                <li><a href="https://www.facebook.com/petvays" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="https://www.instagram.com/petvays/?igshid=YmMyMTA2M2Y%3D" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <!-- <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div> -->

                    <div class="widget widget_fiwan_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <article class="item">
                            <a routerLink="/blogs/is-your-dogs-diet-making-them-sick" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/blogs/is-your-dogs-diet-making-them-sick">Is Your Dog's Diet Making Them Sick? 5 Ways to Pick the Right Food.</a></h4>
                                <ul class="meta">
                                    <li><i class='bx bx-calendar'></i> September 23, 2022</li>
                                    <li><i class='bx bx-time'></i> 2 min</li>
                                </ul>
                            </div>
                        </article>

                    </div>

                  

                    <div class="widget widget_socials_link">
                        <h3 class="widget-title">Stay Connected</h3>

                        <ul>
                            <li>
                                <a href="https://www.facebook.com/petvays" target="_blank">
                                    <i class='bx bxl-facebook'></i>
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/petvays/?igshid=YmMyMTA2M2Y%3D" target="_blank">
                                    <i class='bx bxl-instagram'></i>
                                   Instagram
                                </a>
                            </li>
                          
                        </ul>
                    </div>

                    <div class="widget widget_buy_now">
                        <img src="assets/img/custom-ads.jpg" alt="image">
                        <div class="content">
                            <img src="assets/img/logo.png" alt="image">
                            <p>We found these products available for you.</p>
                            <a href="collection-dog-food" target="_blank" class="buy-now-btn">Buy Now</a>
                        </div>
                    </div>

                 
                </aside>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Details Area -->